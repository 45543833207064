import Swiper from 'swiper/bundle';

var home = new Swiper(".vswiper", {
    direction: "vertical",
    slidesPerView: 1,
    spaceBetween: 0,
    mousewheel: true,
    keyboard: true,
    speed: 500,
    navigation: {
        nextEl: ".scroll",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    on: {
        slideChange:
            function () {
                if (home.activeIndex == 1) {
                    document.getElementById('coaching').classList.add('active');
                } else {
                    document.getElementById('coaching').classList.remove('active');
                    subHome.slideTo(0);
                }
                if (home.activeIndex == 2) {
                    document.getElementById('sextant').classList.add('active');
                } else {
                    document.getElementById('sextant').classList.remove('active');
                }
                if (home.activeIndex == 3) {
                    document.getElementById('training').classList.add('active');
                } else {
                    document.getElementById('training').classList.remove('active');
                }
                if (home.activeIndex == 4) {
                    document.getElementById('contact').classList.add('active');
                } else {
                    document.getElementById('contact').classList.remove('active');
                }
            }

    }
});

var subHome = new Swiper(".hswiper", {
    direction: "horizontal",
    slidesPerView: 1,
    spaceBetween: 0,
    mousewheel: false,
    keyboard: true,
    speed: 500,
    navigation: {
        nextEl: ".hscroll",
    },
    pagination: {
        el: ".hswiper-pagination",
        clickable: true,
    }    
});


window.goto = function (id) {
    home.slideTo(id);
    subHome.slideTo(0);
}

window.onload = function () {
    if (window.location.hash) {
        let hash = window.location.hash.substring(1);
        if (hash == "coaching") {
            home.slideTo(1);
            subHome.slideTo(0);
            document.getElementById('coaching').classList.add('active');
        } else {
            document.getElementById('coaching').classList.remove('active');
        }
        if (hash == "sextant") {
            home.slideTo(2);
            subHome.slideTo(0);
            document.getElementById('sextant').classList.add('active');
        } else {
            document.getElementById('sextant').classList.remove('active');
        }
        if (hash == "training") {
            home.slideTo(3);
            subHome.slideTo(0);
            document.getElementById('training').classList.add('active');
        } else {
            document.getElementById('training').classList.remove('active');
        }
        if (hash == "contact") {
            home.slideTo(4);
            subHome.slideTo(0);
            document.getElementById('contact').classList.add('active');
        } else {
            document.getElementById('contact').classList.remove('active');
        }
    }

};
