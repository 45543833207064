document.addEventListener('DOMContentLoaded', function () {
    
    const templates = document.querySelectorAll('.template');
    
    templates.forEach(template => {
        const file = template.getAttribute('data-template');
        
        fetch(file)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error loading file : ' + file);
                }
                return response.text();
            })
            .then(data => {
                template.innerHTML = data;
            })
            .catch(error => {
                console.error('Error :', error);
            });
    });
});